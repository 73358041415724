import Homecomponent from "./Screen/Homecomponent";
import "./App.css";
// import Router from "./Screen/Router/router";
function App() {
  return (
    <div className="App">
      <Homecomponent />
      {/* <Router/> */}
    </div>
  );
}

export default App;
